import { css } from 'lit-element';
    export const htPandoraNavigationStyles = css `.icon{
  height:2.4rem;
  width:2.4rem; }

.icon .icon--stroke{
  fill:none; }

.icon .icon--fill{
  stroke:none; }

.icon,
.icon circle,
.icon line,
.icon g,
.icon path{
  -webkit-transition:stroke .4s, fill .4s;
  transition:stroke .4s, fill .4s; }

.icon--small{
  height:1.6rem;
  width:1.6rem; }

.icon--medium{
  height:2rem;
  width:2rem; }

.icon--large{
  height:3.2rem;
  width:3.2rem; }

.icon--xlarge{
  height:4.2rem;
  width:4.2rem; }
main{
  display:block; }

.main-content{
  min-height:100vh;
  background:#17171c;
  overflow:visible; }
  .main-content--is-loading{
    visibility:hidden; }

.page{
  display:none;
  height:auto;
  padding:14px 10px; }
  .page__profile{
    padding:0; }

.page[active]{
  display:block; }

button,
svg{
  outline:none; }
  button:active,
  svg:active{
    color:#ffbd00;
    fill:#ffbd00;
    stroke:#ffbd00; }
  @media (min-width: 768px){
    button:focus,
    svg:focus{
      color:#ffbd00;
      fill:#ffbd00;
      stroke:#ffbd00; } }

.floating-wrapper{
  -webkit-box-shadow:0 0 5px 0 rgba(0, 0, 0, 0.5);
          box-shadow:0 0 5px 0 rgba(0, 0, 0, 0.5);
  overflow:auto;
  background-color:#fff;
  position:relative;
  height:100%; }
  .floating-wrapper-form{
    background-color:#fafafa;
    position:relative; }

@media (min-width: 1200px){
  .page{
    padding:20px 30px; }
  .floating-wrapper{
    max-width:calc(100vw - 170px);
    margin-left:-170px; } }
html{
  font-size:62.5%; }

html,
*,
*:before,
*:after{
  -webkit-box-sizing:border-box;
          box-sizing:border-box; }

html{
  -webkit-text-size-adjust:100%;
     -moz-text-size-adjust:100%;
      -ms-text-size-adjust:100%;
          text-size-adjust:100%; }

body{
  font-family:"Hometree", "Arial Black", "sans serif";
  font-size:1.6rem;
  color:#fff;
  font-weight:400;
  text-rendering:optimizelegibility;
  -webkit-font-smoothing:antialiased;
  -moz-font-smoothing:antialiased; }

h1{
  font-size:3.2rem;
  line-height:4rem; }

p{
  font-family:"Hometree", "Arial Black", "sans serif";
  font-size:1.6rem;
  margin-top:1.6rem;
  margin-bottom:0; }

input, p, a, span, li, div, textarea, select{
  font-family:"Hometree", "Arial Black", "sans serif";
  font-size:1.6rem;
  line-height:2.4rem; }
h1,
h2,
h3,
h4,
h5,
h6,
.alpha,
.beta,
.gamma,
.delta,
.epsilon{
  margin:0;
  font-weight:500;
  margin-bottom:0; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small,
  .alpha small,
  .beta small,
  .gamma small,
  .delta small,
  .epsilon small{
    font-weight:normal; }
.text-centre,
.text-center{
  text-align:center; }

.text-left{
  text-align:left; }

.text-right{
  text-align:right; }

.loader{
  position:relative; }
  .loader:after{
    content:'';
    position:absolute;
    top:calc(50% - 2rem);
    left:calc(50% - 2rem);
    border-radius:100%;
    border-style:solid;
    border-width:.3rem;
    width:4rem;
    height:4rem;
    border-color:transparent #ffbd00 #ffbd00 transparent;
    -webkit-animation:spin 1s linear infinite;
            animation:spin 1s linear infinite; }
  .loader--fullscreen{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color:rgba(255, 255, 255, 0.9);
    z-index:2; }

@-webkit-keyframes spin{
  from{
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg); }
  to{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg); } }

@keyframes spin{
  from{
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg); }
  to{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg); } }

.ht-button,
.ht-button-white,
.ht-button-white-transparent,
.ht-button-secondary,
.ht-button-secondary-transparent,
.ht-button-simplified,
.ht-button-simplified-white{
  border:none;
  border-radius:40px;
  color:#111;
  cursor:pointer;
  display:inline-block;
  font-size:1.4rem;
  line-height:1.4rem;
  outline:none;
  padding:15px 30px;
  white-space:nowrap; }

.ht-button,
.ht-button-white,
.ht-button-white-transparent,
.ht-button-secondary,
.ht-button-secondary-transparent,
.ht-button-simplified,
.ht-button-simplified-white{
  margin-left:auto;
  margin-right:auto;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content; }

.ht-button{
  background-color:#ffbd00;
  color:#111;
  -webkit-transition:background-color 0.3s ease, color 0.3s ease;
  transition:background-color 0.3s ease, color 0.3s ease; }
  .ht-button:hover, .ht-button:focus{
    background-color:#b73490;
    color:#fff; }
  .ht-button[disabled]{
    cursor:not-allowed;
    opacity:.3; }

.ht-button-white{
  background-color:#fff;
  -webkit-transition:background-color 0.3s ease;
  transition:background-color 0.3s ease; }
  .ht-button-white:hover, .ht-button-white:focus{
    background-color:#ffbd00; }

.ht-button-danger{
  background-color:#a80000;
  border-color:#a80000;
  color:#fff;
  -webkit-transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  border-style:solid;
  border-width:2px;
  padding:13px 28px; }
  .ht-button-danger:hover, .ht-button-danger:focus{
    background-color:transparent; }

.ht-button-positive{
  background-color:#176d00;
  border-color:#176d00;
  color:#fff;
  -webkit-transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  border-style:solid;
  border-width:2px;
  padding:13px 28px; }
  .ht-button-positive:hover, .ht-button-positive:focus{
    background-color:transparent; }

.ht-button-info{
  background-color:#4270aa;
  border-color:#4270aa;
  color:#fff;
  -webkit-transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  border-style:solid;
  border-width:2px;
  padding:13px 28px; }
  .ht-button-info:hover, .ht-button-info:focus{
    background-color:transparent; }

.ht-button-white-transparent{
  background-color:#fff;
  border-color:#fff;
  color:#111;
  -webkit-transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  border-style:solid;
  border-width:2px;
  padding:13px 28px; }
  .ht-button-white-transparent:hover, .ht-button-white-transparent:focus{
    background-color:transparent;
    color:#fff; }

.ht-button-secondary,
.ht-button-secondary-transparent{
  background-color:#fafafa;
  border-color:#dbdbdb;
  color:#111;
  -webkit-transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  border-style:solid;
  border-width:2px;
  padding:13px 28px; }
  .ht-button-secondary:hover, .ht-button-secondary:focus,
  .ht-button-secondary-transparent:hover,
  .ht-button-secondary-transparent:focus{
    background-color:#b73490;
    border-color:#b73490;
    color:#fff; }

.ht-button-secondary-transparent{
  background-color:transparent;
  border-color:#dbdbdb;
  color:#111;
  -webkit-transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease; }

.ht-button-simplified .ht-button-text,
.ht-button-simplified-white .ht-button-text{
  color:#111;
  -webkit-transition:color 0.3s ease;
  transition:color 0.3s ease;
  position:relative; }
  .ht-button-simplified .ht-button-text:before, .ht-button-simplified .ht-button-text:after,
  .ht-button-simplified-white .ht-button-text:before,
  .ht-button-simplified-white .ht-button-text:after{
    bottom:-5px;
    content:'';
    height:2px;
    left:0;
    position:absolute; }
  .ht-button-simplified .ht-button-text:before,
  .ht-button-simplified-white .ht-button-text:before{
    background-color:#ffbd00;
    width:100%; }
  .ht-button-simplified .ht-button-text:after,
  .ht-button-simplified-white .ht-button-text:after{
    width:0;
    -webkit-transition:width 0.5s ease;
    transition:width 0.5s ease;
    background-color:#b73490; }

.ht-button-simplified:hover .ht-button-text, .ht-button-simplified:focus .ht-button-text,
.ht-button-simplified-white:hover .ht-button-text,
.ht-button-simplified-white:focus .ht-button-text{
  color:#b73490; }
  .ht-button-simplified:hover .ht-button-text:after, .ht-button-simplified:focus .ht-button-text:after,
  .ht-button-simplified-white:hover .ht-button-text:after,
  .ht-button-simplified-white:focus .ht-button-text:after{
    width:100%; }

.ht-button-simplified-white .ht-button-text{
  color:#fafafa;
  -webkit-transition:color 0.3s ease;
  transition:color 0.3s ease; }

.ht-button-white-no-hover:hover, .ht-button-white-no-hover:focus{
  background-color:transparent;
  color:initial; }

.scroll-none::-webkit-scrollbar{
  width:0; }

:host{
  display:-webkit-box;
  display:-webkit-flex;
  display:-ms-flexbox;
  display:flex;
  position:-webkit-sticky;
  position:sticky;
  top:0;
  z-index:10; }

:host([environment='dev']) .navigation{
  background-color:#356c59; }

:host([environment='stage']) .navigation{
  background-color:#cc6200; }

.navigation{
  -webkit-box-align:center;
  -webkit-align-items:center;
      -ms-flex-align:center;
          align-items:center;
  width:100%;
  background-color:#363b44;
  display:-webkit-box;
  display:-webkit-flex;
  display:-ms-flexbox;
  display:flex;
  min-height:50px;
  height:50px;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -webkit-flex-direction:column;
      -ms-flex-direction:column;
          flex-direction:column; }
  .navigation-toggle{
    padding:16px;
    position:absolute;
    right:0;
    z-index:1; }
    .navigation-toggle span{
      background:#f5f5f5;
      border-radius:3px;
      display:block;
      height:2px;
      margin-bottom:4px;
      position:relative;
      -webkit-transform-origin:100% 0;
              transform-origin:100% 0;
      -webkit-transition:background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      transition:background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      transition:transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
      transition:transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      width:27px;
      z-index:4; }
  .navigation.open{
    min-height:100vh; }
    .navigation.open .navigation-toggle span{
      background:#f5f5f5;
      opacity:1;
      -webkit-transform:rotate(-45deg) translate(-2px, -1px);
              transform:rotate(-45deg) translate(-2px, -1px); }
      .navigation.open .navigation-toggle span:nth-child(2){
        opacity:0;
        -webkit-transform:rotate(0) scale(0.2, 0.2);
                transform:rotate(0) scale(0.2, 0.2); }
      .navigation.open .navigation-toggle span:nth-child(3){
        -webkit-transform:rotate(45deg) translate(5px, 6px);
                transform:rotate(45deg) translate(5px, 6px); }
  .navigation__list{
    padding:0;
    margin:0;
    list-style:none;
    position:-webkit-sticky;
    position:sticky;
    top:0;
    max-height:calc(100vh - 10px);
    overflow:auto;
    width:100%; }
  .navigation__link{
    border-left:.8rem solid transparent;
    border-right:15rem solid transparent;
    position:relative;
    width:100%;
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
    text-align:left;
    padding:14px 10px;
    -webkit-transition:background-color .4s, border-color .4s, border-right-width .4s;
    transition:background-color .4s, border-color .4s, border-right-width .4s;
    text-decoration:none;
    cursor:pointer;
    background-color:inherit;
    border:none; }
    .navigation__link .icon{
      min-width:2.4rem;
      min-height:2.4rem; }
    .navigation__link__plus{
      position:absolute;
      top:-14px;
      left:120px;
      padding:3px;
      line-height:0;
      opacity:0;
      border-radius:50%;
      background-color:transparent;
      -webkit-transition:background-color .4s, opacity .4s, left .4s;
      transition:background-color .4s, opacity .4s, left .4s;
      stroke:#fff; }
      .navigation__link__plus:hover{
        stroke:#ffbd00;
        background-color:#17171c; }
    .navigation__link--home{
      border-right-width:.8rem; }
      .navigation__link--home p{
        font-family:"Coaster", "Arial Black", "sans serif";
        font-size:7rem;
        color:#fff; }
    .navigation__link .icon--stroke{
      fill:transparent;
      stroke:#ededed; }
    .navigation__link p{
      color:#ededed;
      margin-top:0;
      margin-left:10px; }
    .navigation__link:hover .icon--stroke{
      stroke:#ffbd00; }
    .navigation__link--updates:before{
      background-color:#a80000;
      border-radius:.5rem;
      bottom:-.3rem;
      content:'';
      display:block;
      height:.5rem;
      left:50%;
      position:absolute;
      -webkit-transform:translateX(-50%);
              transform:translateX(-50%);
      width:.5rem; }
  .navigation__item{
    -webkit-transition:background .4s;
    transition:background .4s;
    position:relative;
    z-index:1; }
    .navigation__item.active{
      background-color:rgba(0, 0, 0, 0.1); }
      .navigation__item.active > .navigation__link .icon--stroke{
        stroke:#ffbd00; }
      .navigation__item.active > .navigation__link p{
        color:#ffbd00; }
    .navigation__item:hover > .navigation__link__plus{
      left:100px;
      opacity:1; }
    .navigation__item.active > .navigation__link ~ .navigation__list > .navigation__item, .navigation__item:hover > .navigation__link ~ .navigation__list > .navigation__item{
      height:40px; }
    .navigation__item .navigation__list{
      overflow:hidden; }
      .navigation__item .navigation__list > .navigation__item{
        height:0;
        -webkit-transition:height .4s;
        transition:height .4s; }
    .navigation__item .navigation__list .navigation__link{
      padding:8px 10px; }
    .navigation__item .navigation__list .navigation__item:hover .navigation__link__plus{
      left:107px; }
    .navigation__item .navigation__list .navigation__link__plus{
      top:8px; }
      .navigation__item .navigation__list .navigation__link__plus .icon{
        height:1.8rem;
        width:1.8rem; }
  .navigation__logo{
    width:100%;
    -webkit-transition:height .4s;
    transition:height .4s; }

.nav-version{
  margin-top:auto;
  padding:14px 10px;
  width:100%;
  text-align:center;
  -webkit-transition:left .4s;
  transition:left .4s;
  position:relative;
  left:-100%; }

:host([page='#/404']) .navigation__link{
  border-right-width:.8rem; }

@media (min-width: 1200px){
  :host{
    z-index:auto; }
  .navigation{
    min-height:100vh;
    height:100%;
    width:28rem; }
    .navigation__link--home{
      border-right-width:.8rem; }
      .navigation__link--home p{
        font-size:12rem;
        line-height:.5;
        position:relative;
        left:0;
        top:0;
        -webkit-transition:left .4s, top .4s, -webkit-transform .4s;
        transition:left .4s, top .4s, -webkit-transform .4s;
        transition:left .4s, top .4s, transform .4s;
        transition:left .4s, top .4s, transform .4s, -webkit-transform .4s; }
    .navigation-toggle{
      display:none; }
  :host([page='']) .navigation__link{
    border-right-width:.8rem; }
  :host([page='']) .navigation__item:hover > .navigation__link__plus{
    left:170px; }
  :host([page='']) .navigation__link.navigation__link--home p{
    left:calc(50vw - 50% + 28rem / 2);
    -webkit-transform:scale(2.5);
            transform:scale(2.5);
    top:50px; }
  :host([page='']) .navigation__logo{
    height:0; }
  :host([page='']) .nav-version{
    left:0; } }
`;
