import { css } from 'lit-element';
    export const htPandoraEditableStyles = css `.icon{
  height:2.4rem;
  width:2.4rem; }

.icon .icon--stroke{
  fill:none; }

.icon .icon--fill{
  stroke:none; }

.icon,
.icon circle,
.icon line,
.icon g,
.icon path{
  -webkit-transition:stroke .4s, fill .4s;
  transition:stroke .4s, fill .4s; }

.icon--small{
  height:1.6rem;
  width:1.6rem; }

.icon--medium{
  height:2rem;
  width:2rem; }

.icon--large{
  height:3.2rem;
  width:3.2rem; }

.icon--xlarge{
  height:4.2rem;
  width:4.2rem; }
main{
  display:block; }

.main-content{
  min-height:100vh;
  background:#17171c;
  overflow:visible; }
  .main-content--is-loading{
    visibility:hidden; }

.page{
  display:none;
  height:auto;
  padding:14px 10px; }
  .page__profile{
    padding:0; }

.page[active]{
  display:block; }

button,
svg{
  outline:none; }
  button:active,
  svg:active{
    color:#ffbd00;
    fill:#ffbd00;
    stroke:#ffbd00; }
  @media (min-width: 768px){
    button:focus,
    svg:focus{
      color:#ffbd00;
      fill:#ffbd00;
      stroke:#ffbd00; } }

.floating-wrapper{
  -webkit-box-shadow:0 0 5px 0 rgba(0, 0, 0, 0.5);
          box-shadow:0 0 5px 0 rgba(0, 0, 0, 0.5);
  overflow:auto;
  background-color:#fff;
  position:relative;
  height:100%; }
  .floating-wrapper-form{
    background-color:#fafafa;
    position:relative; }

@media (min-width: 1200px){
  .page{
    padding:20px 30px; }
  .floating-wrapper{
    max-width:calc(100vw - 170px);
    margin-left:-170px; } }
html{
  font-size:62.5%; }

html,
*,
*:before,
*:after{
  -webkit-box-sizing:border-box;
          box-sizing:border-box; }

html{
  -webkit-text-size-adjust:100%;
     -moz-text-size-adjust:100%;
      -ms-text-size-adjust:100%;
          text-size-adjust:100%; }

body{
  font-family:"Hometree", "Arial Black", "sans serif";
  font-size:1.6rem;
  color:#fff;
  font-weight:400;
  text-rendering:optimizelegibility;
  -webkit-font-smoothing:antialiased;
  -moz-font-smoothing:antialiased; }

h1{
  font-size:3.2rem;
  line-height:4rem; }

p{
  font-family:"Hometree", "Arial Black", "sans serif";
  font-size:1.6rem;
  margin-top:1.6rem;
  margin-bottom:0; }

input, p, a, span, li, div, textarea, select{
  font-family:"Hometree", "Arial Black", "sans serif";
  font-size:1.6rem;
  line-height:2.4rem; }
h1,
h2,
h3,
h4,
h5,
h6,
.alpha,
.beta,
.gamma,
.delta,
.epsilon{
  margin:0;
  font-weight:500;
  margin-bottom:0; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small,
  .alpha small,
  .beta small,
  .gamma small,
  .delta small,
  .epsilon small{
    font-weight:normal; }
.text-centre,
.text-center{
  text-align:center; }

.text-left{
  text-align:left; }

.text-right{
  text-align:right; }

.loader{
  position:relative; }
  .loader:after{
    content:'';
    position:absolute;
    top:calc(50% - 2rem);
    left:calc(50% - 2rem);
    border-radius:100%;
    border-style:solid;
    border-width:.3rem;
    width:4rem;
    height:4rem;
    border-color:transparent #ffbd00 #ffbd00 transparent;
    -webkit-animation:spin 1s linear infinite;
            animation:spin 1s linear infinite; }
  .loader--fullscreen{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color:rgba(255, 255, 255, 0.9);
    z-index:2; }

@-webkit-keyframes spin{
  from{
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg); }
  to{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg); } }

@keyframes spin{
  from{
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg); }
  to{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg); } }

.form-section{
  border-bottom:1px solid #dbdbdb;
  padding:20px 16px; }
  .form-section-active{
    padding:0; }
  .form-section:last-of-type{
    border-bottom:none; }
  .form-section h2{
    color:#363b44;
    margin-right:20px; }
  .form-section-title{
    -webkit-box-align:center;
    -webkit-align-items:center;
        -ms-flex-align:center;
            align-items:center;
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
    margin:0 0 30px; }
    .form-section-title .ht-button{
      margin:0 10px; }
  .form-section[data-navigator='form-section-Paymentstatus']{
    padding:0; }

@media (min-width: 1200px){
  .form-section:not(.form-section-active):not([data-navigator='form-section-Paymentstatus']){
    padding:40px 30px; } }

.ht-button,
.ht-button-white,
.ht-button-white-transparent,
.ht-button-secondary,
.ht-button-secondary-transparent,
.ht-button-simplified,
.ht-button-simplified-white{
  border:none;
  border-radius:40px;
  color:#111;
  cursor:pointer;
  display:inline-block;
  font-size:1.4rem;
  line-height:1.4rem;
  outline:none;
  padding:15px 30px;
  white-space:nowrap; }

.ht-button,
.ht-button-white,
.ht-button-white-transparent,
.ht-button-secondary,
.ht-button-secondary-transparent,
.ht-button-simplified,
.ht-button-simplified-white{
  margin-left:auto;
  margin-right:auto;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content; }

.ht-button{
  background-color:#ffbd00;
  color:#111;
  -webkit-transition:background-color 0.3s ease, color 0.3s ease;
  transition:background-color 0.3s ease, color 0.3s ease; }
  .ht-button:hover, .ht-button:focus{
    background-color:#b73490;
    color:#fff; }
  .ht-button[disabled]{
    cursor:not-allowed;
    opacity:.3; }

.ht-button-white{
  background-color:#fff;
  -webkit-transition:background-color 0.3s ease;
  transition:background-color 0.3s ease; }
  .ht-button-white:hover, .ht-button-white:focus{
    background-color:#ffbd00; }

.ht-button-danger{
  background-color:#a80000;
  border-color:#a80000;
  color:#fff;
  -webkit-transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  border-style:solid;
  border-width:2px;
  padding:13px 28px; }
  .ht-button-danger:hover, .ht-button-danger:focus{
    background-color:transparent; }

.ht-button-positive{
  background-color:#176d00;
  border-color:#176d00;
  color:#fff;
  -webkit-transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  border-style:solid;
  border-width:2px;
  padding:13px 28px; }
  .ht-button-positive:hover, .ht-button-positive:focus{
    background-color:transparent; }

.ht-button-info{
  background-color:#4270aa;
  border-color:#4270aa;
  color:#fff;
  -webkit-transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  border-style:solid;
  border-width:2px;
  padding:13px 28px; }
  .ht-button-info:hover, .ht-button-info:focus{
    background-color:transparent; }

.ht-button-white-transparent{
  background-color:#fff;
  border-color:#fff;
  color:#111;
  -webkit-transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  border-style:solid;
  border-width:2px;
  padding:13px 28px; }
  .ht-button-white-transparent:hover, .ht-button-white-transparent:focus{
    background-color:transparent;
    color:#fff; }

.ht-button-secondary,
.ht-button-secondary-transparent{
  background-color:#fafafa;
  border-color:#dbdbdb;
  color:#111;
  -webkit-transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  border-style:solid;
  border-width:2px;
  padding:13px 28px; }
  .ht-button-secondary:hover, .ht-button-secondary:focus,
  .ht-button-secondary-transparent:hover,
  .ht-button-secondary-transparent:focus{
    background-color:#b73490;
    border-color:#b73490;
    color:#fff; }

.ht-button-secondary-transparent{
  background-color:transparent;
  border-color:#dbdbdb;
  color:#111;
  -webkit-transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  transition:background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease; }

.ht-button-simplified .ht-button-text,
.ht-button-simplified-white .ht-button-text{
  color:#111;
  -webkit-transition:color 0.3s ease;
  transition:color 0.3s ease;
  position:relative; }
  .ht-button-simplified .ht-button-text:before, .ht-button-simplified .ht-button-text:after,
  .ht-button-simplified-white .ht-button-text:before,
  .ht-button-simplified-white .ht-button-text:after{
    bottom:-5px;
    content:'';
    height:2px;
    left:0;
    position:absolute; }
  .ht-button-simplified .ht-button-text:before,
  .ht-button-simplified-white .ht-button-text:before{
    background-color:#ffbd00;
    width:100%; }
  .ht-button-simplified .ht-button-text:after,
  .ht-button-simplified-white .ht-button-text:after{
    width:0;
    -webkit-transition:width 0.5s ease;
    transition:width 0.5s ease;
    background-color:#b73490; }

.ht-button-simplified:hover .ht-button-text, .ht-button-simplified:focus .ht-button-text,
.ht-button-simplified-white:hover .ht-button-text,
.ht-button-simplified-white:focus .ht-button-text{
  color:#b73490; }
  .ht-button-simplified:hover .ht-button-text:after, .ht-button-simplified:focus .ht-button-text:after,
  .ht-button-simplified-white:hover .ht-button-text:after,
  .ht-button-simplified-white:focus .ht-button-text:after{
    width:100%; }

.ht-button-simplified-white .ht-button-text{
  color:#fafafa;
  -webkit-transition:color 0.3s ease;
  transition:color 0.3s ease; }

.ht-button-white-no-hover:hover, .ht-button-white-no-hover:focus{
  background-color:transparent;
  color:initial; }

.section{
  color:#111;
  padding:30px 0;
  position:relative; }

.form-section-title{
  margin-bottom:34px;
  color:#363b44; }

.info{
  margin-bottom:20px;
  color:#363b44; }
`;
